import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from '../components/onecol'
import Twocol from '../components/twocol'
import Cards from '../components/cards'
import ImageText from '../components/imagetext'
import ImageCTA from '../components/imageCTA'
import ButtonCTA from '../components/buttonCTA'
import { graphql } from 'gatsby'

import Banner from '../components/banner'
import ApparelBanner from '../images/apparel-banner.jpg'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Waterbottle from '../images/water-bottle.png'
import Clothes from '../images/apparel.jpg'
import Computer from '../images/macbook.jpg'

import { Briefcase } from 'tabler-icons-react'
import { Heart } from 'tabler-icons-react'
import { Paperclip } from 'tabler-icons-react'
import { Star } from 'tabler-icons-react'
import { BuildingPavilon } from 'tabler-icons-react'

export default function Promo({data}) {
  
    return (
      <Layout>
         <SEO 
            title="Promotional Products" description="Need affordable, custom branded giveaways & company merchandise to get noticed? Gain loyal clients with our wide selection of promo products."
        />

        <Banner
          title="Promotional Products"
          description="Boost your organization’s visibility and help gain loyal clients and customers. It’s a low-cost way to achieve your marketing goals."
          img={ApparelBanner}
        />
  
        {data.allStrapiPromoProducts.edges.map(({ node }) => (
          
          <>
         <Onecol
            key={node.promo_intro.id}
            title={node.promo_intro.one_col_title}
            description={node.promo_intro.one_col_description}
        />

        <Cards 
            key={node.promo_page_cards.id}
            linkOne="/promotional/apparel-bags"
            iconOne={
            <Briefcase
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
          /> 
            }
            titleOne={node.promo_page_cards.title_one}
            linkOne="/promotional/apparel-bags"
            descriptionOne={node.promo_page_cards.description_one}
            linkTwo="/promotional/health-safety"
            iconTwo={
            <Heart
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
            }
            titleTwo={node.promo_page_cards.title_two}
            linkTwo="/promotional/health-safety"
            descriptionTwo={node.promo_page_cards.description_two}
            linkThree="/promotional/office-supplies"
            iconThree={
            <Paperclip
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
            }
            titleThree={node.promo_page_cards.title_three}
            descriptionThree={node.promo_page_cards.description_3}
        />      

        <ButtonCTA />

        <Twocol
        key={node.promo_two_col_left.id}
        image={Waterbottle}
        grey={true}
        textLeft={false}
        intro={node.promo_two_col_left.two_col_intro}
        iconOne={
            <Star
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleOne={node.promo_two_col_left.title_one}
        linkOne="/promotional/promo-items"
        descriptionOne={node.promo_two_col_left.description_one}
        iconTwo={
            <BuildingPavilon
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleTwo={node.promo_two_col_left.title_two}
        linkTwo="/promotional/tradeshows-events"
        descriptionTwo={node.promo_two_col_left.description_two}
    />

    <ImageText
        key={node.promo_two_col_cta.id} 
        background={Clothes}
        title={node.promo_two_col_cta.promo_intro}
        description={node.promo_two_col_cta.promo_description}
        buttonLink="https://www.iopromo.ca/"
        buttonTitle="visit iopromo.ca"
    />

    <ImageCTA
        title="We provide custom, professional websites and digital services."
        image={Computer}
        link="/contact-us"
        linkText="inquire today"
    />
          </>
        ))}  
      </Layout>
    )
    
  }
  

  
  export const query = graphql`
  query Promo {
    allStrapiPromoProducts {
      edges {
        node {
          promo_hero {
            id
            hero_title
            hero_description
          }
          promo_intro {
            id
            one_col_title
            one_col_description
          }
          promo_page_cards {
            id
            title_one
            description_one
            title_two
            description_two
            title_three
            description_3
          }
          promo_two_col_left {
            id
            two_col_intro
            title_one
            description_one
            title_two
            description_two
          }
          promo_two_col_cta {
            id
            promo_intro
            promo_description
            promo_button
          }
        }
      }
    }
  }
  
`